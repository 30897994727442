<template>
  <div class="container-fluid">
    <h4 class="my-4 text-white">Listado de Grupos</h4>
    <div class="card mb-4 ">
      <div class="card-body">
        <div class="col-12 p-0 d-flex justify-content-end mb-3" v-if="GruposList">
          <router-link :to="{name:'GruposCreate'}" class="btn btn-success btn-sm ml-2"><i class="fas fa-plus"></i> Crear Grupo</router-link>
        </div>
        <div class="border p-4" v-if="GruposList || GruposCreate">
          <table class="table table-striped">
            <thead>
              <tr>
                <!-- <th scope="col">#</th> -->
                <th scope="col">Nombre</th>
                <th scope="col">Status</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, key) in gruposList" :key="key">
                <!-- <th scope="row">{{i.id}}</th> -->
                <td>{{i.name}}</td>
                <td>
                  <span class="badge cursor-pointer" :class="i.status ? 'badge badge-success':'badge-danger'" @click="cambiarStatus(i)">
                    {{ i.status ? 'Activo':'Inactivo' }}
                  </span>
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm" @click="copyLink(i.id)" title="Copiar Link"><i class="far fa-copy"></i></button>
                  <router-link :to="{ name: 'GruposResults', params: { id: i.id, name: i.name }}" class="btn btn-success btn-sm ml-2"><i class="fas fa-eye"></i></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { auth } from '../main.js'
import { mapState } from 'vuex'
import swal from 'sweetalert';
import { GruposModel } from '../../../mixins/models/grupos.js' 
export default {
  name: 'GruposList',
  mixins:[GruposModel],
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Grupos',['gruposList']),
    GruposList(){
      return this.$route.name == 'GruposList' ? true:false
    },
    GruposCreate(){
      return this.$route.name == 'GruposCreate' ? true:false
    },
  },
  mounted(){
    this.getGrupos()
  },
  methods:{
    copyLink(id){
      var URLdomain = window.location.host;
      const link = URLdomain+'/test?TestGrupo='+id
      var aux = document.createElement("input");
      aux.setAttribute("value", link);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      swal("Enlace Copiado", "", "success");
    },
    cambiarStatus(e){
      var status = !e.status
      this.updated(e.id,status)
    }
  }
}
</script>

<style>
.cursor-pointer{
  cursor: pointer;
}
</style>
