<template>
  <div class="container" style="margin-top: 8em" >
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h1>Formaciones</h1>
      <div>
        <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
          <button type="button" 
                class="btn " 
                :class="catActive == e ? 'btn-primary':'btn-light'" 
                v-for="(e, key) in categorias" :key="key"
                @click="filterActive(e)"
                >{{ e }}</button>
        </div>
      </div>
    </div>
    <section id="courses" class="courses">
      <div class="row" >
        <boxCurso
          v-for="(e, key) in cursosFilter" :key="key"
          :element="e"
        ></boxCurso>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import boxCurso from '../../components/Home/boxCurso.vue'
export default {
  name: 'Formaciones',
  components: {
    boxCurso
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Courses',['cursos','categorias','catActive']),
    ...mapGetters('Courses',['cursosFilter'])
  },
  create(){
    window.scrollTo( 0, 0 );
  },
  mounted(){
    // console.log(this.cursos)
    window.scrollTo( 0, 0 );
  },
  methods:{
    ...mapMutations('Courses',['filterActive']),
    
  }
}
</script>

<style lang="scss">
.btn-primary{
  background: #FF6F00;
  border-color: #FF6F00;
  &:hover, &:focus{
    background: #FFA000 !important;
    border-color: #FFA000;
    box-shadow: none;
  }

}
</style>
