<template>
  <div class="asidemenu">
    <ul>
      <li>
        <router-link  :to="{name:'CardMenu'}" class="">Inicio</router-link>
      </li>
      <li>
        <router-link  :to="{name:'PacientesList'}" class="">Pacientes</router-link>
      </li>
      <li>
        <router-link :to="{name:'GruposList'}" class="">Grupos</router-link>
      </li>
      <li>Config
        <ul>
          <li>
            <router-link :to="{name: 'Preguntas'}" class="">Preguntas</router-link>
          </li>
        </ul>
      </li>
      <li> <router-link :to="{name: 'CursosList'}" class="">Cursos</router-link></li>
      <li> <router-link :to="{name: 'AsistencialList'}" class="">Asistencial</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'AsideMenu',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{

  },
  mounted(){
  
  },
  methods:{
 
    
  }
}
</script>

<style lang="scss">
  .asidemenu{
    background: #EEEEEE;
    height: 100%;
    left: 0px;
    min-height: 100vh;
    padding-top: 120px;
    padding: 15px;
    position: fixed;
    top: 0px;
    top: 0px;
    width: 20vw;
    z-index: 9999;
  }
</style>
