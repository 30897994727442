<script>
import { Pie } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculos } from '../mixins/calculos.js'
export default {
  mixins:[calculos],
  extends: Pie,
  data: () => ({
    
  }),
  computed:{
    ...mapState('Test',['rasgos']),
    rigido(){
      return this.filtroRasgo('RÍGIDO') 
    },
    histerico(){
      var data = this.filtroRigidos(this.rigido,'HISTERICO')
      var verdaderos = this.conteoVerdaderos(data)
      var numero = (verdaderos*100)/data.length
      return Math.round(numero)/4
    },
    pasivoFemenino(){
      var data = this.filtroRigidos(this.rigido,'PASIVO FEMENINO')
      var verdaderos = this.conteoVerdaderos(data)
      var numero = (verdaderos*100)/data.length
      return Math.round(numero)/4
    },
    masculinoAgresivo(){
      var data = this.filtroRigidos(this.rigido,'MASCULINO AGRESIVO')
      var verdaderos = this.conteoVerdaderos(data)
      var numero = (verdaderos*100)/data.length
      return Math.round(numero)/4
    },
    falicoNarcisista(){
      var data = this.filtroRigidos(this.rigido,'FALICO-NARCISISTA')
      var verdaderos = this.conteoVerdaderos(data)
      var numero = (verdaderos*100)/data.length
      return Math.round(numero)/4
    },
    chartdata() {
      return {
        labels: ['HISTERICO', 'PASIVO FEMENINO','MASCULINO AGRESIVO','FALICO-NARCISISTA'],
        datasets: [
          {
            label: 'Rasgo Rígido',
            backgroundColor: [
                '#E47272',
                '#E48F72',
                '#E4AB72',
                '#E4C872',
            ],
            data: [this.histerico, this.pasivoFemenino, this.masculinoAgresivo, this.falicoNarcisista]
            // data:[20,30,50,40]
          }
        ]
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted () {
    // console.log(this.histerico)
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>