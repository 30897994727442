<template>
  <div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 col-sm-6 mb-3 mb-sm-0 col-md-4 col-lg-3">
          <router-link :to="{name:'PacientesList'}">
            <div class="card h-100 bg-dark text-white overflow-hidden">
              <img class="card-img" :src="require('../../../assets/paciente.jpg')" style="width: 120%"  alt="Card image">
              <div class="card-img-overlay d-flex justify-content-center align-items-center">
                <h2 class="card-title">Pacientes</h2>
              </div>
            </div>
          </router-link>
        </div>
      
        <div class="col-12 col-sm-6 mb-3 mb-sm-0 col-md-4 col-lg-3">
          <router-link :to="{name:'GruposList'}">
            <div class="card h-100 bg-dark text-white overflow-hidden">
              <img class="card-img" :src="require('../../../assets/grupo.jpg')"  alt="Card image">
              <div class="card-img-overlay d-flex justify-content-center align-items-center">
                <h2 class="card-title">Grupos</h2>
              </div>
            </div>
          </router-link>
        </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'AsideMenu',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{

  },
  mounted(){
  
  },
  methods:{
    
  }
}
</script>

<style>
  .card-img-overlay{
    background-color: rgba(0,0,0,.5);
  }
</style>
