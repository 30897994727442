<template>
  <div class="container" style="margin-top: 8em">
   <h1>Consultas</h1>
   <div class="mt-5">
   <section id="cource-details-tabs" class="cource-details-tabs">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-3">
            <ul class="nav nav-tabs flex-column">
              <li class="nav-item" v-for="(e, key) in servicios" :key="key">
                <a class="nav-link  " :class="key == 0 ? 'active show':''" data-toggle="tab" :href="`#tab-${e.id}`">{{ e.name }}</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-9 mt-4 mt-lg-0">
            <div class="tab-content">

              <div class="tab-pane " :class="key == 0 ?'active show':''" :id="`tab-${e.id}`" v-for="(e, key) in servicios" :key="key">
                <div class="row">
                  <div class="col-12 mb-3">
                    <img :src="`${URL_IMG}${e.img}`" alt="" class="img-fluid">
                  </div>
                  <div class="col-lg-12 details order-2 order-lg-1">
                    <h3>{{ e.name }}</h3>
                    <p class="font-italic">{{ e.duracion }}</p>
                    <p>{{ e.descripcion }}</p>
                    <div class="d-flex tags">
                      <h3 v-for="(i,ky) in e.categoria" :key="ky" :style="{backgroundColor: `${i.color}`}">{{ i.name }}</h3>
                    </div>

                    <div class="w-100 d-flex justify-content-end">
                      <a :href="`https://api.whatsapp.com/send?phone=541132954245&text=${stringLink(e.name)}`" target="_blank" class="btn btn-primary col-4">Reservar</a>
                    </div>
                    
                  </div>
                  <!-- <div class="col-lg-4 text-center order-1 order-lg-2">
                    <img :src="`${e.img}`" alt="" class="img-fluid">
                  </div> -->
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
    </div>
  </div>
</template>

<script>
import { mapState,mapActions } from 'vuex'
export default {
  name: '',
  components: {

  },
  data(){
    return{
       URL_IMG: process.env.VUE_APP_IMG
    }
  },
  computed:{
    ...mapState('Services', ['servicios']),

  },
  mounted(){
    this.getServices()
  },
  methods:{
    ...mapActions('Services',['getServices']),
    stringLink(name){
      var text = 'Hola quiero reservar una cosulta de '+name
      return text.replace(' ','%20')
    }
  }
}
</script>

<style lang="scss" scoped="">
.tags{
  h3{
    color: white;
  font-size: 15px;
  padding: 8px;
  margin-right: 5px;
  }
}
</style>
