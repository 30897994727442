import axios from 'axios'

export default {
  namespaced: true,
  state:{
    consultas:[
      {
        id: 1,
        categoria: [{name:'Presencial', color: '#546E7A'}, {name:'Online', color:'#039BE5'}],
        name: 'Tratamiento de conmoción cerebral',
        duracion: '1 hr',
        descripcion: 'Con éste y otros servicios he tratado a múltiples pacientes durante mi carrera como Fisioterapeuta. Para mí nada es más importante que su bienestar y salud, el principal motivo por el que nunca he dejado de formarme en diversos aspectos de mi especialidad. Por favor, póngase en contacto para pedir una consulta.',
        img:'https://picsum.photos/801/150',
      },
      {
        id: 2,
        categoria: [{name:'Presencial', color: '#546E7A'}, {name:'Online', color:'#039BE5'}],
        name: 'Terapia para deportistas',
        duracion: '1 hr',
        descripcion: 'Mi enfoque holístico de la medicina me ayuda a considerar a cada paciente como si fuera un todo en lugar de solo tratar el síntoma. Este es uno de los varios procedimientos que aplico al realizar diagnósticos. Su salud merece la atención adecuada y yo estoy aquí para brindársela. Póngase en contacto hoy mismo para pedir una cita.',
        img:'https://picsum.photos/802/150',
      },{
        id: 3,
        categoria: [{name:'Presencial', color: '#546E7A'}, {name:'Online', color:'#039BE5'}],
        name: 'Tratamiento postoperatorio',
        duracion: '1 hr',
        descripcion: 'Desde hace años, los pacientes acuden a mi consulta para recibir este servicio. Ofrezco ésta y otros servicios personalizados a cualquiera que necesite un(a) Fisioterapeuta de confianza. Soy Asdrubal Noguera, un profesional de la medicina comprometido con los principios de confianza e integridad médica en el ejercicio de su profesión.',
        img:'https://picsum.photos/800/150',
      }
    ],
    servicios: [],
    category:[],
    serviceActive: null
  },
  getters:{
    filterService(state){
    if (state.servicios) {
      return state.servicios.find((element)=> element.id == state.serviceActive)
    }
    return null
   }
  },
  mutations:{
    addService(state, val){
      state.servicios.push(val)
    },
    changeServiceActive(state, val){
      state.serviceActive = val
    },
    deleteService(state, val){
      let index = state.servicios.findIndex((element) => element.id == val)
      console.log(index)
      state.servicios.splice(index, 1)
    }
  },
  actions:{
    getServices(context){
      axios.get(process.env.VUE_APP_API+'/admin/services').then(function (response) {
        context.state.servicios = response.data.servicios
        context.state.category = response.data.category
      }).catch(function (error) {
        console.log(error);
      });
    },
    async SenData(context, data){
      let formData = new FormData()
      formData.append('name',               data.name)
      formData.append('status',             data.status)
      formData.append('duracion',           data.duracion)
      formData.append('descripcion',        data.descripcion)
      formData.append('img',                data.img)
      formData.append('categorias',         JSON.stringify(data.categorias))
      let url = process.env.VUE_APP_API+'/admin/services'
      if (data.edit) {
        url = process.env.VUE_APP_API+'/admin/services/'+data.id
      }
      await axios.post(url,formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response)=> {
        
        if (data.edit) {
          // context.commit('changeCurso',response.data)
        }else{
          context.commit('addService',response.data)
        }
        
        return response.data
      }).catch(function (error) {
        console.log(error);
      });
    },
    destroyService(context, val){
      axios.post(process.env.VUE_APP_API+'/admin/services/destroy/'+val).then(function (response) {
        console.log(response.data.mensaje)
        context.commit('deleteService',val)
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
}
