import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import { db } from '../main.js'
export const database = {
	data(){
		return{
			nameModel: 'pacientes'
		}
	},
	computed:{
		...mapState('Test',['rasgos','idPaciente']),
	},
	methods:{
		...mapMutations('Test',['LlenarIDPaciente','ExisteUsuario','llenarDatosPaciente']),
		...mapMutations('Pacientes',['LlenarData','LlenaPacienteTest']),
		...mapMutations('Grupos',['LlenarPacientesGrupos']),
		consultaEmail(email){

			db.collection(this.nameModel).where("email", "==", email).get().then((querySnapshot)=>{
				var existe = false
				this.ExisteUsuario(false)
				querySnapshot.forEach(()=>{
					existe = true
					this.ExisteUsuario(true)
				});

				if (!existe) {
					this.add(this.name, this.mail, this.TestGrupo, this.sexo)
					this.cambioPasoAction()
				}
			})
			.catch(function(error) {
				console.log("Error getting documents: ", error);
			});
		},
		add(name, email, TestGrupo, sexo){
			var objeto = {
				name: name,
				email: email,
				TestGrupo: TestGrupo,
				sexo: sexo,
				created_at: moment().format('DD-MM-YYYY')
			}
			this.llenarDatosPaciente(objeto)
			db.collection(this.nameModel).add(objeto)
			.then((docRef)=> {
				this.LlenarIDPaciente(docRef.id)
				
			})
			.catch(function(error) {
				console.error("Error adding document: ", error);
			});
		},
		updated(){
			db.collection(this.nameModel).doc(this.idPaciente).set({
				name: "Los Angeles",
				state: "CA",
				country: "USA"
			})
			.then(function(e) {
				console.log(e)
				console.log("Document successfully written!");
			})
			.catch(function(error) {
				console.error("Error writing document: ", error);
			});
		},
		get(){
			var data = []
			db.collection(this.nameModel).get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {

					data.push({
						id: doc.id,
						datos: doc.data()
					})
				});
			});
			this.LlenarData(data)
		},
		delet(id){
			db.collection(this.nameModel).doc(id).delete().then(function() {
				console.log("Document successfully deleted!");
				alert('Eliminado')
				location.reload();
			}).catch(function(error) {
				console.error("Error removing document: ", error);
			});
		},
		getPaciente(id){
			var data = {}
			db.collection(this.nameModel).doc(id).get().then((doc)=> {
				data = doc.data()
				data.id = doc.id
				this.LlenaPacienteTest(data)
			})
			.catch(function(error) {
				console.log("Error getting documents: ", error);
			});
		},
		getPacientesGrupos(grupoID){
			var data = []
			var dat = {}
			db.collection(this.nameModel).where('TestGrupo', "==", grupoID).get().then((querySnapshot)=> {
				querySnapshot.forEach((doc)=> {
					dat = doc.data()
					dat.id = doc.id
					data.push(dat)
				});
				this.LlenarPacientesGrupos(data)
			})
			.catch(function(error) {
				console.log("Error getting documents: ", error);
			});
		}
	}
}