<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6">
        <h4 class="my-4 text-white">Crea una nueva pregunta</h4>
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="guardarNueva">
              <div class="form-group">
                <label for="pregunta">Pregunta</label>
                <input type="text" class="form-control" id="pregunta" name="pregunta" v-model="pregunta" placeholder="" required="">
              </div>
              <div class="form-group">
                <label for="rasgo">Rasgo</label>
                <select name="rasgo" id="rasgo" v-model="rasgo" class="form-control">
                  <option :value="null">Seleccione</option>
                  <option :value="i" v-for="(i, key) in rasgosTipos" :key="key"> {{i}} </option>
                </select>
              </div>
              <div class="form-group" v-if="rasgo == 'RÍGIDO'">
                <label for="subcategoria">SubCategoría</label>
                <select name="subcategoria" id="subcategoria" v-model="subcategoria" class="form-control">
                  <option :value="null">Seleccione</option>
                  <option :value="i" v-for="(i, key) in subcategoriasRigidos" :key="key"> {{i}} </option>
                </select>
              </div>
              <div class="form-group">
                <label for="categoria">Categoría</label>
                <select name="categoria" id="categoria" v-model="categoria" class="form-control">
                  <option :value="null">Seleccione</option>
                  <option :value="i" v-for="(i, key) in categorias" :key="key"> {{i}} </option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <h4 class="my-4 text-white">Crea una nueva pregunta</h4>
        <div class="row">
            <div class="col-xl-6 col-md-6" v-for="(i, key) in conteo" :key="key">
                <div class="card text-white mb-4" :style="i.background">
                    <div class="card-body">
                      <h5 class="text-white">{{i.rasgo}}</h5>
                      <h5 class="text-white">{{i.data.length}} Preguntas</h5>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    
    <h4 class="my-4 text-white">Preguntas del Test</h4>
    <div class="card">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="">#</th>
              <th scope="col">Pregunta</th>
              <th scope="col">Rasgo</th>
              <th scope="col">SubCategoría</th>
              <th scope="col">Categoria</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="(i,key) in rasgos" :key="key">
              <th><input type="text" class="form-control" :disabled="true" v-model="i.id"></th>
              <td><textarea class="form-control" :disabled="editandose != i.id" v-model="i.pr" ></textarea></td>
              <td>
                <select class="form-control" :disabled="editandose != i.id" v-model="i.rasgo">
                  <option :value="u" v-for="(u, key) in rasgosTipos" :key="key">{{u}}</option>
                </select>
              </td>
              <td>
                <select class="form-control" :disabled="editandose != i.id" v-model="i.subcategoria">
                  <option :value="null">Seleccione</option>
                  <option :value="u" v-for="(u, key) in subcategoriasRigidos" :key="key">{{u}}</option>
                </select>
              </td>
              <td>
                <select class="form-control" :disabled="editandose != i.id" v-model="i.categoria">
                  <option :value="u" v-for="(u, key) in categorias" :key="key">{{u}}</option>
                </select>
              </td>
              <td>
                <button type="button" class="btn btn-warning btn-sm" v-if="editandose != i.id" @click="editar(i)"><i class="fas fa-edit"></i> Editar</button>
                <button type="button" class="btn btn-success btn-sm" v-if="editandose == i.id" @click="guardar(i)"><i class="fas fa-save"></i> Guardar</button>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PeguntasModel } from '../../../mixins/models/Preguntas.js'
import swal from 'sweetalert';
export default {
  name: 'Preguntas',
  mixins:[PeguntasModel],
  components: {

  },
  data(){
    return{
      editandose: null,
      rasgo: null,
      subcategoria: null,
      categoria: null,
      pregunta:null,
    }
  },
  watch:{
    rasgos: function(){
      this.cuentalos()
    }
  },
  computed:{
    ...mapState('Test',['rasgos','rasgosTipos','subcategoriasRigidos','categorias','backgrounds']),
    conteo(){
      return this.cuentalos()
    }
  },
  created(){
    this.getPreguntas()
  },
  mounted(){

  },
  methods:{
    editar(element){
      this.editandose = element.id
    },
    guardar(element){
      if (element.subcategoria === null) {
        delete element['subcategoria']
      }
      if (this.valida(element)) {
        this.updatedPregunta(element) 
        this.editandose = null
      }
    },
    valida(element){
      if (element.pr == '') {
        swal("Espera :(", "El campo de la pregunta se encuentra vacio. Verifica e intentalo nuevamente", "error")
        return false
      }
      if (element.rasgo != 'RÍGIDO') {
        if (Object.prototype.hasOwnProperty.call(element, "subcategoria")) {
          swal("Espera :(", "Las subcategorias son solo para el rasgo rígido. Verifica e intentalo nuevamente", "error")
          return false
        }
      }
      return true
    },
    guardarNueva(){
      var element = {
        pr: this.pregunta,
        rasgo: this.rasgo,
        categoria: this.categoria
      }
      if (this.rasgo == 'RÍGIDO') {
        element.subcategoria = this.subcategoria
      }
      this.addPregunta(element)

    },
    cuentalos(){
      var data = []
      this.rasgosTipos.forEach((rt)=>{
        data.push({rasgo: rt,data:[]})
      })
      this.rasgos.forEach((r)=>{
        data.forEach((d)=>{
          if (d.rasgo == r.rasgo) {
            d.data.push(r)
          }
        })
      })
      data.forEach((d, i)=>{
        this.backgrounds.forEach((b)=>{
          if (i == b.id) {
            d.background = { backgroundColor: b.color }
          }
        })
      })
      return data
    }
  }
}
</script>

<style>

</style>
