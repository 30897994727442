<script>
import { HorizontalBar } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculosGrupales } from '../../mixins/calculosGrupales.js'
export default {
  props:{
    categoria:{
      type: String,
    },
    ragosDePacientes:{
      type: Array,
      require: true
    }
  },
  mixins:[calculosGrupales],
  extends: HorizontalBar,
  data: () => ({
    
  }),
  computed:{
    ...mapState('Test',['rasgosTipos','backgrounds']),
    rasgosCalculo(){
      var datos = {}
      var data = []
      var rasgos = []
      var paciente
      this.rasgosTipos.forEach((rt)=>{
        this.ragosDePacientes.forEach((e)=>{
          if (e != undefined) {
            datos = {
              rasgo: rt,
              datos: this.filtroRasgo(e, rt)
            }
            data.push(datos)
          }
        })
      })
      for (var i = 0; i < data.length; i++) {
          paciente = data[i];
          var rasgoAct;
          var rasgo = {};
          if (rasgoAct !== paciente.rasgo) {
              rasgoAct = paciente.rasgo;
              rasgo.nombre = rasgoAct;
              rasgo.datos = data.filter(paciente => paciente.rasgo === rasgoAct)
              rasgos.push(rasgo);
          }
      }
      rasgos.map((rs)=>{
        var rsatos = [] 
        rs.datos.forEach((rd)=>{
          rsatos.push(rd.datos)
        })
        rs.datos = rsatos
        rs.categoria = this.categoria
        rs.categoriaDatos = this.conteoVerdaderos(this.filterCategoria(rsatos.flat(),this.categoria))
        rs.numero = Math.round((this.conteoVerdaderos(rsatos.flat())*100)/rsatos.flat().length)
      })
      return rasgos
    },
    chartdata() {
      var data = []
      this.rasgosCalculo.forEach((rc)=>{
        data.push({
          label: rc.nombre,
          data: [rc.categoriaDatos]
        })
      })
      data.map((dt,i)=>{
        this.backgrounds.filter((bg)=>{
          if (bg.id == i) {
            dt.backgroundColor = bg.color
          }
        })
      })
      return {
        labels: ['R.C'],
        datasets: data
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            // stacked: true,
            ticks: {
              beginAtZero: true,
            }
          }],
          yAxes: [{
             // stacked: true
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>