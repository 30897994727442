<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Atencion Asistencial</h1>
      <span>
      <router-link  :to="{name:'CreateService'}" class="btn btn-warning">Nuevo</router-link>
      </span>
    </div>
    
    <table class="table">
      <thead>
        <tr>
          <!-- <th>Categoría</th> -->
          <th>Estatus</th>
          <th>Nombre</th>
          <th>Categorias</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c, key) in servicios" :key="key">
          <!-- <td>{{ c.categoria.description }}</td> -->
          <td>{{ c.status ? 'Activo':'Inactivo' }}</td>
          <td>{{ c.name }}</td>
          <td><span class="category" v-for="(cc, k) in c.categoriass" :key="k" :style="{background: cc.color}">{{ cc.name }}</span></td>
          <td>
            <div class="d-flex btn-group">
              <button type="button" class="btn btn-primary" @click="edit(c)">Editar</button>
              <button type="button" class="btn btn-danger"  @click="eliminar(c)">Eliminar</button>
              <!-- <button type="button" class="btn btn-info"    @click="change(c)">{{ !c.status ? 'Activar':'Desactivar' }}</button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: '',
  mixins:[],
  components: {
    
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Services',['servicios','serviceActive']),
  },
  mounted(){
    window.scrollTo( 0, 0 );
    this.getServices()
  },
  methods:{
    ...mapActions('Services',['getServices','destroyService']),
    ...mapMutations('Services',['changeServiceActive']),
    edit(service){
      this.changeServiceActive(service.id)
      this.$router.push({name:'EditService',params:{ id: service.id}})
    },
    eliminar(service){
      if (confirm('Eliminar?')) {
        this.destroyService(service.id)
      }
    },
   
  }
}
</script>

<style lang="scss">
  .category{
    padding: 5px;
    font-weight: bold;
    color: white;
    &:first-child{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
</style>
