<template>
  <div class="container" style="margin-top: 8em">
    <h1>La Asociación</h1>
    <p class="font-italic">“Nada es absoluto, todo es relativo”.</p>
    
    <section id="" class="about">
      <div class="" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="home/assets/img/nosotros1.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <!-- <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3> -->
            <p>
              Somos un grupo de especialistas de distintas disciplinas vinculadas a la Salud Pública que trabajamos bajo un enfoque inter y transdisciplinario: gerencia, educación, medicina, mercadeo y publicidad, psicología, trabajo social, enfermería, psicopedagogía, fisioterapia, terapia ocupacional, nutricionistas, psicoterapeutas corporales y terapeutas gestálticos, quienes en un plano de respeto y consideración queremos asumir el compromiso de difundir, estudiar y valorar al ser humano como unidad funcional, dentro de un trabajo de comprensión  y manejo del cuerpo humano, como elemento esencial de la salud y el bienestar del individuo. Lo cual ha demostrado que el apoyo educativo, psicosocial y emocional junto a un abordaje físico, previene las enfermedades psicosomáticas y promueve la salud, la calidad de vida y el  desarrollo social de nuestra sociedad. 
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="" class="about">
      <div class="" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-6 order-1 " data-aos="fade-left" data-aos-delay="100">
            <img src="home/assets/img/nosotros2.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 content">
            <!-- <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3> -->
             <ul>
              <li><i class="icofont-check-circled"></i> 
                Constituir un grupo de especialistas de diversas disciplinas vinculadas a la Salud Pública, que nos permita valorar al ser humano desde un enfoque psicocorporal, como principio esencial de la salud y el bienestar del individuo.
              </li>
              <li><i class="icofont-check-circled"></i> 
                Estimular la investigación concerniente a los beneficios de valorar, intervenir y tratar al ser humano desde una visión psicocorporal.
              </li>
              <li><i class="icofont-check-circled"></i>
              Proporcionar herramientas o recursos que beneficien el desarrollo del bienestar Psicofísico de la población. 
              </li>
              <li><i class="icofont-check-circled"></i>
              Apoyar la realización de eventos, programas y difusión de información por medios de comunicación que fomenten la salud integral de la población. 
              </li>
              <li><i class="icofont-check-circled"></i>
              Fortalecer  la educación y capacitación (cursos, diplomados y postgrados) que proporcionen competencias a especialistas de las áreas de: gerencia, educación, medicina, mercadeo y publicidad, psicología, trabajo social, enfermería, psicopedagogía, fisioterapia, terapia ocupacional, nutricionistas, psicoterapeutas corporales y terapeutas gestálticos,  que sirvan como entes multiplicadores del sano uso de las herramientas psicocorporales en nuestra población. 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="" class="about">
      <div class="" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-7 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="home/assets/img/nosotros3.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-5 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <!-- <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3> -->
            <p>
              Establecer un compromiso solidario entre los  diferentes especialistas y grupos de profesionales vinculados con la salud pública a nivel internacional desde un enfoque inter y transdisciplinario, con el fin de divulgar, estudiar y valorar al ser humano como unidad funcional, dentro de un trabajo de comprensión  y manejo del cuerpo humano, como elemento esencial de la salud y el bienestar del individuo.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Association',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
  
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>

<style lang="scss">

</style>
