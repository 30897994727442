<template>
  <router-view/>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PacientesIndex',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Pacientes',['data']),
  },
  mounted(){
  
  },
  methods:{
 
    
  }
}
</script>

<style>

</style>
