<template>
  <div class="">
    <section class="bg" :style="bg">
      <div class="position-relative d-flex align-items-center w-100 h-100 text-white pt-5 text-center text-sm-right overlay">
        <div class="container d-flex justify-content-center justify-content-sm-end">
          <div class="col-12 col-sm-9">
           <h1 class="font-weight-bold mt-3 titleT">Valoración de la influencia de los rasgos del caracter en tu patrón de personalidad</h1>
           <h2 class="title2">por Asdrúbal Noguera</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="container" >
      <div class="row">
        <div class="col-11 col-md-9 mx-auto mt-n5">
          <div class="shadow  mb-5 bg-white rounded cont" v-if="!ultimoPaso" style="">
            <div class="progress">
              <div class="progress-bar" :style="`width:${paso}%`" role="progressbar" :aria-valuenow="paso" aria-valuemin="0" :aria-valuemax="rasgosAleatorios.length"></div>
            </div>
            
            <div v-show="key == paso" v-for="(i,key) in rasgosAleatorios" :key="key" >
              <transition name="fade">
                <Forms
                :obj="i"
                :paso="paso"
                v-if="key == paso"
                ></Forms>
              </transition>
            </div>
            <div class="p-4" v-if="paso == null">
              <p>A continuación, se te presenta una serie de preguntas relacionadas con características que definen los diferentes rasgos, formas de manifestarse o expresarse el patrón de la personalidad de cada persona.</p>

              <p>Responde de manera sincera haciendo clic en sí o no, según la similitud que tenga el enunciado con tu historia de vida (etiología u origen), con la manera de pensar (sistema de creencia), la manera de manifestar tus emociones y aspecto somático o físico-energético que logras identificar en ti.</p>
              <form @submit.prevent="iniciar">
                <div class="row">

                  <div class="form-group col-md-6 col-12">
                    <label for="exampleInputEmail1">Nombres</label>
                    <input type="text" class="form-control" id="name" name="name" v-model="name" required="" >
                  </div>

                  <div class="form-group col-md-6 col-12">
                    <label for="exampleInputEmail1">Email</label>
                    <input type="email" class="form-control" id="mail" name="mail" v-model="mail" required="">
                  </div>

                  <div class="form-group form-group-label col-md-6 col-12">
                    
                    <input type="radio" class="form-control-custom-radio" id="sexoNo" name="sexo" v-model="sexo" value="M" >
                    <label for="sexoNo" class="form-control-custom-label"> Masculino </label>
                     
                    <br>
                    
                    <input type="radio" class="form-control-custom-radio" id="sexoSi" name="sexo" v-model="sexo" value="F" >
                    <label for="sexoSi" class="form-control-custom-label">Famenino</label>
                      
                    
                  </div>

                  <input type="hidden" class="form-control" readonly="" v-if="TestGrupo != null" disabled="" id="TestGrupo" name="TestGrupo" v-model="TestGrupo" required=""> 

                </div>
                <button class="btn btn-primary mt-4 " style="margin-right: auto; margin-left: auto; display:block; text-transform: uppercase; font-weight: bold; font-size: 18px;" :disabled="isActive" type="submit">{{nameButton}}</button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-12 mb-5 mt-5" v-if="ultimoPaso">
          <div class="shadow h-100 mb-5 p-4 bg-white rounded">
            <h5>Gráfica General</h5>
            <hr>
            <Graf></Graf>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-5 mt-5" v-if="ultimoPaso">
          <div class="shadow h-100 mb-5 p-4 bg-white rounded">
            <h5>Caractéristicas rígidas</h5>
            <hr>
            <GrafRigidoCircle></GrafRigidoCircle>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-5" v-show="ultimoPaso" v-for="(i, key) in categorias" :key="key" >
          <div class="shadow h-100 mb-5 p-4 bg-white rounded">
            <h5>{{ i }}</h5>
            <hr>
            <BarHorizontal 
            v-if="paso >= rasgosAleatorios.length" :categoria="i"></BarHorizontal>
            <BarHorizontalRigido v-if="paso >= rasgosAleatorios.length" :categoria="i"></BarHorizontalRigido>
          </div>
        </div>
        <div class="col-12 mb-5" v-if="ultimoPaso">
          <div class="shadow h-100 mb-5 p-4 bg-white rounded">
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">ITEM</th>
                  <th scope="col">RASGO</th>
                  <th scope="col">CATEGORÍA</th>
                  <th scope="col">SUBCATEGORIA</th>
                </tr>
              </thead>
              <tbody v-for="(i,key) in porRasgo" :key="key">
                <tr class="table-secondary">
                  <td colspan="5">{{ i.rasgo }}</td>
                </tr>
                <tr v-for="(e, ke) in i" :key="ke" :class="e.selected ? 'bg-success text-white':''">
                  <th scope="row">{{ ke+1 }}</th>
                  <td>{{ e.pr }}</td>
                  <td>{{ e.rasgo }}</td>
                  <td>{{ e.categoria }}</td>
                  <td>{{ e.subcategoria }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Forms from '../../components/form.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Graf from '../../components/Graf.vue'
import GrafRigidoCircle from '../../components/GrafRigidoCircle.vue'
import BarHorizontal from '../../components/BarHorizontal.vue'
import BarHorizontalRigido from '../../components/BarHorizontalRigido.vue'
import { calculos } from '../../mixins/calculos.js'
import { database } from '../../mixins/database.js'
import { PeguntasModel } from '../../mixins/models/Preguntas.js'
import swal from 'sweetalert';
import moment from 'moment'

export default {
  name: 'App',
  mixins:[calculos,database,PeguntasModel],
  components: {
    Forms,
    Graf,
    GrafRigidoCircle,
    BarHorizontal,
    BarHorizontalRigido
  },
  data(){
    return{
      name: '',
      mail: '',
      TestGrupo: null,
      bg:{ backgroundImage: 'url('+require('../../assets/bg.jpg')+')',  },
      isActive: true,
      sexo: ''
    }
  },
  watch:{
    rasgos:function(){
      this.isActive = false
    },
    existe: function(val){
      // console.log(val)
      if (val){
        swal("Espera :(", "Ya realizaste este test. Comunicate con el administrador si deseas ver tus resultados o realizarlo nuevamente", "error")
      }
    },
    sexo: function(val){
      if (val != '') {
        this.changeSex(val)
      }
      
    }
  },
  computed:{
    ...mapState('Test',['rasgos','paso','ultimoPaso','rasgosTipos','categorias','existe','sex']),
    ...mapGetters('Test',['rasgosAleatorios']),
    nameButton(){
      if (this.paso != null) {
        return 'Siguiente'
      }
      return 'Iniciar Test'
    },
    porRasgo(){
      var data = []
      this.rasgosTipos.forEach((r)=>{
        data.push(this.filtroRasgo(r))
      })

      data.map((e,i)=>{
        this.rasgosTipos.forEach((r,t)=>{
          if (i == t) {
            e.rasgo = r
          }
        })
      })
      return data
    },
    porCategorias(){
      var data = []
      this.categorias.forEach((e)=>{
        data.push(this.filterCategoria(this.rasgos,e))
      })
      return data;
    }
  },
  created(){
    this.getPreguntas()
    if (this.$route.query.TestGrupo) {
      this.TestGrupo = this.$route.query.TestGrupo
    }
  },
  mounted(){
    
  },
  methods:{
    ...mapMutations('Test',['cambioPaso','changeSex','changedatosP']),
    ...mapActions('Test',['cambioPasoAction']),
    iniciar(){  

      if ((this.name != '') && (this.mail != '') && this.sexo != '') {
        this.changedatosP({
          name: this.name,
          email: this.email,
          // TestGrupo: TestGrupo,
          sexo: this.sexo,
          created_at: moment().format('DD-MM-YYYY')
        })
        this.consultaEmail(this.mail)
      }else{
        swal("Espera :(", "Debes llenar tu correo e email y seleccionar un sexo para continuar", "error")
        return false
      }
    }
  }
}
</script>

<style lang="scss">
  .bg{
    
    @media(min-width: 675px){
      padding: 130px;
    }
  }
  .titleT, .title2{
     @media(max-width: 675px){
      font-size: 16px;
    }
    
  }
  .form-group-label{
    display: flex;
  }

  input[type=radio]{

    visibility: hidden;
    display: none;

    & + label{
        padding: 10px;
        color: black;
        border-radius: 5px;
        border: 3px solid rgba(#FF6F00, .3);
        margin-right: 10px;
        cursor: pointer;
    }

    &:checked{
      & + label{
        background: #FF6F00;
        border: 3px solid #FF6F00;
        color: white;
      }
    }
    
  }

</style>
