import Vue from 'vue'
import App from './App.vue'
import store from './storage/store.js'
import router from './router/index.js'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';


var firebaseConfig = {
	apiKey: "AIzaSyBK0ZJl1ZyEdxzIyZrMfHkTeFwazyTcHMs",
	authDomain: "test-f4682.firebaseapp.com",
	databaseURL: "https://test-f4682.firebaseio.com",
	projectId: "test-f4682",
	storageBucket: "test-f4682.appspot.com",
	messagingSenderId: "240048265827",
	appId: "1:240048265827:web:2ee7e1cd85da4600f3f656",
	measurementId: "G-WGSTMLRM0B"
	
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const auth = firebase.auth();

Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')

