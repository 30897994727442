<template>
  <div class="container" style="margin-top: 8em" v-if="cursoDetail">
    <section id="course-details" class="course-details">
      <div class="container" data-aos="fade-up">

        <div class="row" >
          <div class="col-lg-8">
            <img :src="`${URL_IMG}${cursoDetail.img}`" class="img-fluid" alt="">
            <h3>{{ cursoDetail.name }}</h3>
            <div v-html="cursoDetail.descripcion"></div>
          </div>
          <div class="col-lg-4">

            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Profesor</h5>
              <p><a href="#">{{ cursoDetail.profesor.name }}</a></p>
            </div>


            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Duración</h5>
              <p>{{ cursoDetail.duracion }}</p>
            </div>

            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Próxima Fecha</h5>
              <p>{{ cursoDetail.fecha }}</p>
            </div>
            <div class="course-info d-flex justify-content-between align-items-center">
              <h5>Horario</h5>
              <p>{{ cursoDetail.horario }}</p>
            </div>

            <div class="w-100">
              <a :href="`https://api.whatsapp.com/send?phone=541132954245&text=Hola%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20uno%20de%20los%20${stringLink}`" target="_blank" class="btn btn-primary w-100">Mas Información</a>
            </div>

          </div>
        </div>

      </div>
    </section>
    <!-- End Cource Details Section -->

    <!-- ======= Cource Details Tabs Section ======= -->
    <section id="cource-details-tabs" class="cource-details-tabs">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-3">
            <ul class="nav nav-tabs flex-column">

              <li class="nav-item" v-for="(e, key) in cursoDetail.contenidos" :key="key">
                <a class="nav-link" :class="key == 0 ? 'active show':''" data-toggle="tab" :href="`#tab-${e.id}`">{{ e.name }}</a>
              </li>
              
            </ul>
          </div>
          <div class="col-lg-9 mt-4 mt-lg-0">
            <div class="tab-content">

              <div class="tab-pane" :class="key == 0 ? 'active show':''" :id="`tab-${e.id}`" v-for="(e, key) in cursoDetail.contenidos" :key="key">
                <div class="row">
                  <div class="col-lg-8 details order-2 order-lg-1">
                    <h3>{{ e.name }}</h3>
                    <div v-html="e.descripcion"></div>
                  </div>
                  <!-- <div class="col-lg-4 text-center order-1 order-lg-2">
                    <img :src="e.img" alt="" class="img-fluid">
                  </div> -->
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'CoursesDetail',
  components: {

  },
  data(){
    return{
      URL_IMG: process.env.VUE_APP_IMG
    }
  },
  watch:{
    cursoDetail: 'mount'
  },
  computed:{
    ...mapGetters('Courses',['cursoDetail']),
    stringLink(){
      if (this.cursoDetail) {
        var str =  this.cursoDetail.categoria +' de '+this.cursoDetail.name
        return str.replace(' ','%20')
      }
    return ''
    }
  },
  created(){
    this.cursoActive(this.$route.params.slug)
    window.scrollTo( 0, 0 );
  },
  mounted(){
    window.scrollTo( 0, 0 );
  },
  methods:{
    ...mapMutations('Courses',['cursoActive']),
    ...mapActions('Courses',['getContenido']),
    mount(){
      console.log(this.cursoDetail)
      // this.getContenido(this.cursoDetail.id) 
    }
  }
}
</script>

<style lang="scss">

</style>
