<template>
  <div class="container-fluid">
    <h4 class="my-4 text-white">{{pacienteTest.name}}</h4>
    <div class=" row" v-if="disponibles">
      <div class="col-md-8 col-12 mb-5 " >
        <div class="shadow h-100 mb-5 p-4 bg-white rounded">
          <h5>Gráfica General</h5>
          <hr>
          <Graf></Graf>
        </div>
      </div>
      <div class="col-md-4 col-12 mb-5 " >
        <div class="shadow h-100 mb-5 p-4 bg-white rounded">
          <h5>Caractéristicas rígidas</h5>
          <hr>
          <GrafRigidoCircle></GrafRigidoCircle>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-5" v-for="(i, key) in categorias" :key="key" >
        <div class="shadow h-100 mb-5 p-4 bg-white rounded">
          <h5>{{ i }}</h5>
          <hr>
          <BarHorizontal  :categoria="i"></BarHorizontal>
          <BarHorizontalRigido :categoria="i"></BarHorizontalRigido>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { database } from '../../../mixins/database.js'
import { mapState, mapMutations } from 'vuex'
import Graf from '../../../components/Graf.vue'
import GrafRigidoCircle from '../../../components/GrafRigidoCircle.vue'
import BarHorizontal from '../../../components/BarHorizontal.vue'
import BarHorizontalRigido from '../../../components/BarHorizontalRigido.vue'
export default {
  name: 'results',
  mixins:[database],
  components: {
    Graf,
    GrafRigidoCircle,
    BarHorizontal,
    BarHorizontalRigido
  },
  data(){
    return{
      disponibles: false
    }
  },
  watch:{
    pacienteTest: function(val){
      this.modificaRasgos(val.rasgos)
      this.disponibles = true
    }
  },
  computed:{
    ...mapState('Pacientes',['pacienteTest']),
    ...mapState('Test',['rasgos','categorias']),
  },
  created(){
    if (this.$route.params.id) {
      this.getPaciente(this.$route.params.id)
    }else{
      this.$router.replace('/404')
    }
  },
  mounted(){
    
  },
  methods:{
    ...mapMutations('Test',['modificaRasgos']),
  }
}
</script>

<style>

</style>
