<template>
  <div class="admin" style="min-height: 100vh">
    
   <!--  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark d-flex">
        <a class="navbar-brand" href="index.html">Logo</a>

        <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                    
                    <button type="button" class="dropdown-item" @click="logOut">Cerrar Sesión</button>
                </div>
            </li>
        </ul>
    </nav> -->
    <div id="layoutSidenav">
      <AsideMenu></AsideMenu>
      <div id="layoutSidenav_content">
        <main>
          <router-view/>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../../main.js'
import AsideMenu from './menu/AsideMenu.vue'
import { database } from '../../mixins/database.js'
import { mapActions } from 'vuex'
export default {
  name: 'Admin',
  mixins:[database],
  components: {
    AsideMenu
  },
  data(){
    return{
      email: null
    }
  },
  computed:{
  },
  mounted(){
    this.user()
    this.get()
    this.getCursos()
  },
  methods:{
    ...mapActions('AdminCourses',['getCursos']),
    logOut() {
      auth.signOut().then( () => {
        this.$router.replace('/login')
      })
    },
    user(){
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.email = user.email
        }
      })
    },
  }
}
</script>

<style lang="scss">
  #layoutSidenav_content{

    min-width: 70vw;
    min-height: 90vh;
    margin-left: 20vw;
    margin-top: 15vh;
    padding: 15px;
  }

</style>
