import Vue from 'vue'
import Vuex from 'vuex'
import Test from './Test.js'
import Pacientes from './Pacientes.js'
import Grupos from './Grupos.js'
import Home from './Home.js'
import Courses from './Courses.js'
import Services from './Services.js'
// 
import AdminCourses from './admin/Courses.js'

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
		Test,
		Pacientes,
		Grupos,
		Home,
		Courses,
		Services,
		AdminCourses
    },
})

export default store;