export default {
  namespaced: true,
  state:{
    routers:[
        
        { name: 'Formaciones',           link: '/courses' },
        { name: 'Consultas',             link: '/consultations', 
          // drop :[
          //   { name: 'Online',            link: '/online' },
          //   { name: 'Presenciales',      link: '/face-to-face' },
          // ]
        },
        { name: 'La asociación',         link: '/association' },
        // { name: 'Trabajar con nosotros', link: '/work' },
        { name: 'Contacto',              link:'/contact'}
      ]

  },
  getters:{
  },
  mutations:{
    
  },
  actions:{
    
  }
}
