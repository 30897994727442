<script>
import { Bar } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculos } from '../mixins/calculos.js'
export default {
  mixins:[calculos],
  extends: Bar,
  data: () => ({
    
  }),
  computed:{
    ...mapState('Test',['rasgos']),
    esquizoide(){
      return this.filtroRasgo('ESQUIZOIDE')
    },
    oral(){
      return this.filtroRasgo('ORAL') 
    },
    psicopatico(){
      return this.filtroRasgo('PSICOPÁTICO') 
    },
    rigido(){
      return this.filtroRasgo('RÍGIDO') 
    },
    masoquista(){
      return this.filtroRasgo('MASOQUISTA') 
    },
    calculoEsquizoide(){
      var verdaderos = this.conteoVerdaderos(this.esquizoide)
      var numero = (verdaderos*100)/this.esquizoide.length
      return Math.round(numero)
    },
    calculoOral(){
      var verdaderos = this.conteoVerdaderos(this.oral)
      var numero = (verdaderos*100)/this.oral.length
      return Math.round(numero)
    },
    calculoPsicopatico(){
      var verdaderos = this.conteoVerdaderos(this.psicopatico)
      var numero = (verdaderos*100)/this.psicopatico.length
      return Math.round(numero)
    },
    calculoRigido(){
      var verdaderos = this.conteoVerdaderos(this.rigido)
      var numero = (verdaderos*100)/this.rigido.length
      return Math.round(numero)
    },
    calculoMasoquista(){
      var verdaderos = this.conteoVerdaderos(this.masoquista)
      var numero = (verdaderos*100)/this.masoquista.length
      return Math.round(numero)
    },
    chartdata() {
      return {
        labels: ['Rasgos del Caracter'],
        datasets: [
          {
            label: 'ESQUIZOIDE',
            backgroundColor:'#7AE73C',
            data: [this.calculoEsquizoide]
          },
          {
            label: 'ORAL',
            backgroundColor:'#3CD0E7',
            data: [this.calculoOral]
          },
          {
            label: 'PSICOPÁTICO',
            backgroundColor:'#A93CE7',
            data: [this.calculoPsicopatico]
          },{
            label: 'MASOQUISTA',
            backgroundColor:'#E51FC1',
            data: [this.calculoMasoquista]
          },{
            label: 'RÍGIDO',
            backgroundColor:'#E7533C',
            data: [this.calculoRigido]
          }
          
        ]
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>