<script>
import { Pie } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculosGrupales } from '../../mixins/calculosGrupales.js'
export default {
  props:{
    ragosDePacientes:{
      type: Array,
      require: true
    }
  },
  mixins:[calculosGrupales],
  extends: Pie,
  data: () => ({

  }),
  computed:{
    ...mapState('Test',['subcategoriasRigidos']),
    rasgosCalculos(){
      var rigidos = []
      var data = []
      var rasgos = []
      var paciente
      this.ragosDePacientes.forEach((rp)=>{
        if (rp != undefined) {
          rigidos.push(this.filtroRasgo(rp,'RÍGIDO'))
        }
      })
      this.subcategoriasRigidos.forEach((sr)=>{
        rigidos.forEach((r)=>{
          data.push({
            nombre : sr,
            datos : this.filtroRigidos(r,sr)
          })
        })
      })
      for (var i = 0; i < data.length; i++) {
          paciente = data[i];
          var rasgoAct;
          var rasgo = {};
          if (rasgoAct !== paciente.nombre) {
              rasgoAct = paciente.nombre;
              rasgo.nombre = rasgoAct;
              rasgo.datos = data.filter(paciente => paciente.nombre === rasgoAct)
              rasgos.push(rasgo);
          }
      }
      var numero = 0
      rasgos.map((rs)=>{
        var rsatos = [] 
        rs.datos.forEach((rd)=>{
          rsatos.push(rd.datos)
        })
        rs.datos = rsatos
        rs.datosUnidos = rsatos.flat()
        rs.verdaderas = this.conteoVerdaderos(rsatos.flat())
      })

      rasgos.forEach((rs)=>{
        numero += rs.verdaderas
      })

      rasgos.map((rs)=>{
        rs.total = Math.round((rs.verdaderas/numero)*100)
      })
      return rasgos
    },
    chartdata() {
      var data = []
      var label = []
      this.rasgosCalculos.forEach((rc)=>{
        label.push(rc.nombre)
        data.push(rc.total)
      })
      return {
        labels: label,
        datasets: [
          {
            label: 'Rasgo Rígido',
            backgroundColor: [
                '#E47272',
                '#E48F72',
                '#E4AB72',
                '#E4C872',
            ],
            data:data
          }
        ]
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>