<template>
  <div>
    <h1>Servicio</h1>
    <form action="#" @submit.prevent="Sen" enctype="multipart/form-data" method="post">
      <div class="row" v-if="service">
        <div class="form-group col-4">
          <label for="">Nombre</label>
          <input type="text" v-model="service.name" class="form-control">
        </div>
        <div class="form-group col-4">
          <label for="">Estatus</label>
          <select name="" class="form-control" v-model="service.status" id="">
            <option value="">Seleccione</option>
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
        </div>
        
        <div class="form-group col-4">
          <label for="">Duracion</label>
          <input type="text" v-model="service.duracion" class="form-control">
        </div>
        
        <div class="form-group col-4">
          <label for="">img</label>
          <input type="file" @change="changeFile" ref="file" class="form-control">
        </div>
         
        <div class="form-group col-4">
          <label for="">descripcion</label>
          <textarea name="" v-model="service.descripcion" class="form-control" id="" cols="30" rows="10"></textarea>
        </div>
      </div>
      <div class="d-flex">
        <div class="form-check" v-for="(c, key) in category" :key="key">
          <input class="form-check-input" type="checkbox" v-model="service.categorias" :value="c.id" :id="c.id" >
          <label class="form-check-label" :for="c.id">
            {{ c.name }}
          </label>
        </div>
      </div>

      <div class="form-group col-12 mt-5">
        <input type="submit" class="btn btn-primary" value="Guardar">
      </div>
    </form>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import swal from 'sweetalert';
export default {
  name: '',
  mixins:[],
  components: {
    
  },
  data(){
    return{
      service: {
        name:                null,
        duracion: null,
        img: null,
        status:              '',
        descripcion:         null,
        categorias: []
      },
    }
  },
  watch:{
    
  },
  computed:{
    ...mapState('Services',['category']),
  },
  mounted(){
window.scrollTo( 0, 0 );
  },
  methods:{
    ...mapActions('Services',['SenData']),
    Sen(){
      this.service.edit = false
      this.SenData(this.service)
      swal('Registro almacenado')
      this.$router.push({name:'AsistencialList'})
    },
    changeFile(){
       this.service.img = this.$refs.file.files[0];
    },
  }
}
</script>

<style lang="scss">
.form-check-input{
  &~ .form-check-label{
    background: transparent;
    color: black;
    border: 1px solid black;
  }
  &:checked{
    &~ .form-check-label{
      background: #FF6F00;
      color: white;
      border-color: #FF6F00;
    }
  }
}
</style>
