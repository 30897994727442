<script>
import { HorizontalBar } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculos } from '../mixins/calculos.js'
export default {
  props:{
    categoria:{
      type: String,
    }
  },
  mixins:[calculos],
  extends: HorizontalBar,
  data: () => ({
    
  }),
  computed:{
    ...mapState('Test',['rasgos']),
    rigido(){
      return this.filtroRasgo('RÍGIDO') 
    },
    RigidoCategoria(){
      return this.filterCategoria(this.rigido, this.categoria)
    },
    falicoNarcisista(){
      return Math.round(this.conteoVerdaderos(this.filtroRigidos(this.RigidoCategoria,'FALICO-NARCISISTA')))
    },
    historicoNarcisista(){
      return Math.round(this.conteoVerdaderos(this.filtroRigidos(this.RigidoCategoria,'HISTERICO')))
    },
    pasivofemeninoNarcisista(){
      return Math.round(this.conteoVerdaderos(this.filtroRigidos(this.RigidoCategoria,'PASIVO FEMENINO')))
    },
    masculinoagresivoNarcisista(){
      return Math.round(this.conteoVerdaderos(this.filtroRigidos(this.RigidoCategoria,'MASCULINO AGRESIVO')))
    },
    chartdata() {
      return {
        labels: ['Rasgo Rígido'],
        datasets: [
          {
            label: 'FALICO-NARCISISTA',
            backgroundColor:'#E47272',
            data: [this.falicoNarcisista]
          },
          {
            label: 'HISTERICO',
            backgroundColor:'#E48F72',
            data: [this.historicoNarcisista]
          },
          {
            label: 'PASIVO FEMENINO',
            backgroundColor:'#E4AB72',
            data: [this.pasivofemeninoNarcisista]
          },
          {
            label: 'MASCULINO AGRESIVO',
            backgroundColor:'#E4C872',
            data: [this.masculinoagresivoNarcisista]
          }
        ]
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            }
          }],
          yAxes: [{
             stacked: true
          }]
        }
      }
    }
  },
  mounted () {
    // console.log(this.falicoNarcisista)
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>