import { mapState } from 'vuex'
export const calculosGrupales = {
	data(){
		return{

		}
	},
	computed:{
		...mapState('Test',['rasgos']),
	},
	methods:{
		filtroRasgo(data, name){
			var rasgo = []
			data.filter((e)=>{
				if (e.rasgo == name) {
					rasgo.push(e)
				}
			})
			return rasgo
		},
		conteoVerdaderos(data){
			var verdaderos = []
			data.filter((e)=>{
				if (e.selected) {
					verdaderos.push(e)
				}
			})
			return verdaderos.length
		},
		filtroRigidos(data,name){
			var subcategoria = []
			data.filter((e)=>{
				if (e.subcategoria == name) {
					subcategoria.push(e)
				}
			})
			return subcategoria
		},
		filterCategoria(data,categoria){
			var date = []
				data.filter((e)=>{
					if (e.categoria ==categoria) {
						date.push(e)
					}
				})
			return date
		}
	}
}