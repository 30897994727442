<template>
  <div class="formTest">
    <div class="p-4 d-flex align-items-center" style="min-height: 150px;">{{obj.pr}}</div>
    <div class=" d-flex">
      <div class="form-check position-relative col-6 col-sm-2 pb-4">
        <input class="form-check-input" type="radio" name="evalua" id="SI" :value="obj.id">
        <label class="form-check-label w-100" for="SI" 
        @click="responde(obj.id),cambioPasoAction()">
         SI
        </label>
      </div>
      <div class="form-check position-relative col-6 col-sm-2 pb-4">
        <input class="form-check-input" type="radio" name="evalua" id="NO" :value="false">
        <label class="form-check-label w-100" for="NO" 
        @click="responde(false),cambioPasoAction()">
         NO
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'Forms',
  props:{
    paso:{
      type: Number,
      defalut: 0
    },
    obj:{
      type: Object,
    }
  },
  components: {
    
  },
  data(){
    return{
      valid: true
    }
  },
  computed:{
    
  },
  mounted(){

  },
  methods:{
    ...mapMutations('Test',['responde']),
    ...mapActions('Test',['cambioPasoAction']),
  }
}
</script>

<style lang="scss">
  .form-check-input{
    visibility: hidden;
  }
  .form-check-label{
    background-color: #3D5AFE;
    color: white;
    font-weight: bold; 
    padding: 10px 15px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background-color: #304FFE;
    }
  }
</style>
