import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { db } from '../../main.js'
import swal from 'sweetalert';
export const GruposModel = {
	data(){
		return{
			nameModel: 'Grupos'
		}
	},
	computed:{
		...mapState('Grupos',['grupoActivo']),
	},
	methods:{
		...mapMutations('Grupos',['LlenarDataGrupos','LimpiarDataGrupos','LlenarPacientesGrupos']),
		addGrupo(name, status){
			db.collection(this.nameModel).add({
				name: name,
				status: status,
				created_at: moment().format('DD-MM-YYYY')
			})
			.then((docRef)=> {
				this.LlenarDataGrupos({
					id: docRef.id,
					name: name,
					status: status,
					created_at: moment().format('DD-MM-YYYY')
				})
				swal("Felicidades!!", "Has creado un nuevo grupo", "success").then(()=>{
					this.$router.replace('/admin/grupos/')
				})
			})
			.catch(function(error) {
				console.error("Error adding document: ", error);
			});
		},
		updated(id,status){
			db.collection(this.nameModel).doc(id).set({
				status: status,
			},{ merge: true })
			.then(()=> {
				console.log("Document successfully written!");
				this.LimpiarDataGrupos()
				this.getGrupos()
			})
			.catch(function(error) {
				console.error("Error writing document: ", error);
			});
		},
		getGrupos(){
			var data = []
			db.collection(this.nameModel).get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					console.log(doc.data())
					data.push({
						id: doc.id,
						name: doc.data().name,
						status: doc.data().status,
					})
				});
				this.LlenarDataGrupos(data)
			});
			
		},
		
	}
}