<script>
import { HorizontalBar } from 'vue-chartjs'
import { mapState } from 'vuex'
import { calculos } from '../mixins/calculos.js'
export default {
  props:{
    categoria:{
      type: String,
    }
  },
  mixins:[calculos],
  extends: HorizontalBar,
  data: () => ({
    
  }),
  computed:{
    ...mapState('Test',['rasgos']),
    esquizoide(){
      return this.filtroRasgo('ESQUIZOIDE')
    },
    oral(){
      return this.filtroRasgo('ORAL') 
    },
    psicopatico(){
      return this.filtroRasgo('PSICOPÁTICO') 
    },
    rigido(){
      return this.filtroRasgo('RÍGIDO') 
    },
    masoquista(){
      return this.filtroRasgo('MASOQUISTA') 
    },
    EsquizoideCategoria(){
      return Math.round(this.conteoVerdaderos(this.filterCategoria(this.esquizoide, this.categoria)))
    },
    OralCategoria(){
      return Math.round(this.conteoVerdaderos(this.filterCategoria(this.oral, this.categoria)))
    },
    PsicopaticoCategoria(){
      return Math.round(this.conteoVerdaderos(this.filterCategoria(this.psicopatico, this.categoria)))
    },
    RigidoCategoria(){
      return Math.round(this.conteoVerdaderos(this.filterCategoria(this.rigido, this.categoria)))
    },
    MasoquistaCategoria(){
      return Math.round(this.conteoVerdaderos(this.filterCategoria(this.masoquista, this.categoria)))
    },
    chartdata() {
      return {
        labels: ['R.C'],
        datasets: [
          {
            label: 'ESQUIZOIDE',
            backgroundColor:'#7AE73C',
            data: [this.EsquizoideCategoria]
          },
          {
            label: 'ORAL',
            backgroundColor:'#3CD0E7',
            data: [this.OralCategoria]
          },
          {
            label: 'PSICOPÁTICO',
            backgroundColor:'#A93CE7',
            data: [this.PsicopaticoCategoria]
          },{
            label: 'MASOQUISTA',
            backgroundColor:'#E51FC1',
            data: [this.MasoquistaCategoria]
          },
          {
            label: 'RÍGIDO',
            backgroundColor:'#E7533C',
            data: [this.RigidoCategoria]
          }
        ]
      }
    },
    options() {
      return{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            // stacked: true,
            ticks: {
              beginAtZero: true,
            }
          }],
          yAxes: [{
             // stacked: true
          }]
        }
      }
    }
  },
  mounted () {
    // console.log(this.EsquizoideCategoria)
    this.renderChart(this.chartdata, this.options)
  },
  methods:{
    
  }
}
</script>

<style>

</style>