<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex justify-content-center align-items-center">
      <div class="container position-relative" data-aos="zoom-in" data-aos-delay="100">
        <h1>ASOFIPSICOS </h1>
        <h2>ASOCIACIÓN PARA EL DESARROLLO PSICO-FÍSICO DE LA SOCIEDAD</h2>
        
        <!-- <a href="courses.html" class="btn-get-started">Get Started</a> -->
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= Popular Courses Section ======= -->
      <section id="popular-courses" class="courses">
        <div class="container" >

          <div class="section-title">
            <h2>Formaciones</h2>
            <p>Cursos Populares</p>
          </div>

           <div class="row" >
            <boxCurso
              v-for="(e, key) in cursosLimit" :key="key"
              :element="e"
            ></boxCurso>
            </div>
            <div class="w-100 text-center mt-4">
              <router-link class="btn btn-primary col-10 col-sm-2"  to="/courses"> Ver más</router-link>
            </div>

        </div>
      </section><!-- End Popular Courses Section -->

      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Asofipsicos</h2>
            <p>La Asociación</p>
          </div>

          <div class="row">
            <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
              <img src="home/assets/img/about.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h3>Somos un grupo de especialistas de distintas disciplinas vinculadas a la Salud Pública que trabajamos bajo un enfoque inter y transdisciplinario</h3>
              <p class="font-italic">
                Lo cual ha demostrado que el apoyo educativo, psicosocial y emocional junto a un abordaje físico, previene las enfermedades psicosomáticas y promueve la salud, la calidad de vida y el  desarrollo social de nuestra sociedad. 
              </p>

              <!-- <ul>
                <li><i class="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="icofont-check-circled"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
              </ul> -->
              <p>
               Abarcamos áreas de gerencia, educación, medicina, mercadeo y publicidad, psicología, trabajo social, enfermería, psicopedagogía, fisioterapia, terapia ocupacional, nutricionistas, psicoterapeutas corporales y terapeutas gestálticos, quienes en un plano de respeto y consideración queremos asumir el compromiso de difundir, estudiar y valorar al ser humano como unidad funcional, dentro de un trabajo de comprensión  y manejo del cuerpo humano, como elemento esencial de la salud y el bienestar del individuo.
              </p>
              <!-- <a href="about.html" class="learn-more-btn">Learn More</a> -->
            </div>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= Counts Section ======= -->
      <section id="counts" class="counts section-bg">
        <div class="container">

          <div class="d-flex justify-content-around flex-wrap counters">

            <div class="col-lg-3 col-12 text-center">
              <span data-toggle="counter-up">200</span>
              <p>Estudiantes</p>
            </div>

            <div class="col-lg-3 col-12 text-center">
              <span data-toggle="counter-up">10</span>
              <p>Cursos</p>
            </div>

            <!-- <div class="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">42</span>
              <p>Events</p>
            </div> -->

            <div class="col-lg-3 col-12 text-center">
              <span data-toggle="counter-up">3</span>
              <p>Profesores</p>
            </div>

          </div>

        </div>
      </section><!-- End Counts Section -->

      <!-- ======= Why Us Section ======= -->
      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">

          <div class="row">
            <div class="col-lg-4 d-flex align-items-stretch">
              <div class="content">
                <h3>Formaciones</h3>
                <p>
                  Encontrarás en el portal información científica actualizada en relación a los beneficios de la utilización de las técnicas psicocorporales en diferentes campos profesionales y personales, también la utilización de otras herramientas o principios teóricos de otras corrientes psicológicas que apoyan la importancia de la relación mente-cuerpo, de igual manera encontrarás información general en salud, dirigida a todo público que con seguridad será de mucho provecho para todos.
                </p>
                <!-- <div class="text-center">
                  <a href="about.html" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
                </div> -->
              </div>
            </div>
            <div class="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="bx bx-receipt"></i>
                      <h4>Cursos</h4>
                      <p>Ofrecemos una variedad de cursos con los que podrás impulsarte profesionalmente en poco tiempo.</p>
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class='bx bxs-bookmark-plus'></i>
                      <h4>Talleres</h4>
                      <p>Mejora tus habilidades y obtén herramientas novedosas para aplicar en tu campo profesional.</p>
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="bx bx-layer"></i>
                      <h4>Diplomados</h4>
                      <p>
                        Profundiza y/o actualiza en temas específicos del área de tu agrado. Estructurados en módulos sobre temas determinados.
                      </p>
                    </div>
                  </div>
                </div>
              </div><!-- End .content-->
            </div>
          </div>

        </div>
      </section><!-- End Why Us Section -->

      <!-- ======= Features Section ======= -->
      <!-- <section id="features" class="features">
        <div class="container" data-aos="fade-up">

          <div class="row" data-aos="zoom-in" data-aos-delay="100">
            <div class="col-lg-3 col-md-4">
              <div class="icon-box">
                <i class="ri-store-line" style="color: #ffbb2c;"></i>
                <h3><a href="">Lorem Ipsum</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <i class="ri-bar-chart-box-line" style="color: #5578ff;"></i>
                <h3><a href="">Dolor Sitema</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <i class="ri-calendar-todo-line" style="color: #e80368;"></i>
                <h3><a href="">Sed perspiciatis</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
              <div class="icon-box">
                <i class="ri-paint-brush-line" style="color: #e361ff;"></i>
                <h3><a href="">Magni Dolores</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-database-2-line" style="color: #47aeff;"></i>
                <h3><a href="">Nemo Enim</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-gradienter-line" style="color: #ffa76e;"></i>
                <h3><a href="">Eiusmod Tempor</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-file-list-3-line" style="color: #11dbcf;"></i>
                <h3><a href="">Midela Teren</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-price-tag-2-line" style="color: #4233ff;"></i>
                <h3><a href="">Pira Neve</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-anchor-line" style="color: #b2904f;"></i>
                <h3><a href="">Dirada Pack</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-disc-line" style="color: #b20969;"></i>
                <h3><a href="">Moton Ideal</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-base-station-line" style="color: #ff5828;"></i>
                <h3><a href="">Verdo Park</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-fingerprint-line" style="color: #29cc61;"></i>
                <h3><a href="">Flavor Nivelanda</a></h3>
              </div>
            </div>
          </div>

        </div>
      </section> -->
      <!-- End Features Section -->

      <!-- ======= Trainers Section ======= -->
      <section id="trainers" class="trainers">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Nos acompañan</h2>
            <p>Nuestros profesionales</p>
          </div>

          <div class="row d-flex justify-content-center" data-aos="zoom-in" data-aos-delay="100">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <img src="home/assets/img/trainers/trainer-1.jpg" class="img-fluid" alt="">
                <div class="member-content">
                  <h4>Asdrúbal Noguera</h4>
                  <span>Director</span>
                  <p>
                    Lic. en Fisioterapeuta. <br> Especialista en reprogramación psicosomática, tecnología educativa, orientación de la conducta humana y rehabilitación neuropediatrica
                  </p>
                  <div class="social">
                    <!-- <a href=""><i class="icofont-twitter"></i></a> -->
                    <a href="https://www.facebook.com/asdrubal.noguera.12"><i class="icofont-facebook"></i></a>
                    <a href="https://www.instagram.com/fipsico/?hl=es-la"><i class="icofont-instagram"></i></a>
                    <a href="https://www.linkedin.com/in/asdrubalnoguera/"><i class="icofont-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>

           <!--  <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <img src="home/assets/img/trainers/trainer-2.jpg" class="img-fluid" alt="">
                <div class="member-content">
                  <h4>Sarah Jhinson</h4>
                  <span>Marketing</span>
                  <p>
                    Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus
                  </p>
                  <div class="social">
                    <a href=""><i class="icofont-twitter"></i></a>
                    <a href=""><i class="icofont-facebook"></i></a>
                    <a href=""><i class="icofont-instagram"></i></a>
                    <a href=""><i class="icofont-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="member">
                <img src="home/assets/img/trainers/trainer-3.jpg" class="img-fluid" alt="">
                <div class="member-content">
                  <h4>William Anderson</h4>
                  <span>Content</span>
                  <p>
                    Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara
                  </p>
                  <div class="social">
                    <a href=""><i class="icofont-twitter"></i></a>
                    <a href=""><i class="icofont-facebook"></i></a>
                    <a href=""><i class="icofont-instagram"></i></a>
                    <a href=""><i class="icofont-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div> -->

          </div>

        </div>
      </section><!-- End Trainers Section -->

    </main><!-- End #main -->
  </div>
</template>

<script>

import boxCurso from '../../components/Home/boxCurso.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'home',
  components: {
    boxCurso
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters('Courses',['cursosLimit']),
  },
  mounted(){
    console.log(this.cursosLimit)
  },
  methods:{
    
  }
}
</script>

<style lang="scss">

</style>
