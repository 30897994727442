<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Cursos</h1>
      <span>
      <router-link  :to="{name:'CreateCurso'}" class="btn btn-warning">Nuevo Curso</router-link>
      </span>
    </div>
    
    <table class="table">
      <thead>
        <tr>
          <th>Categoría</th>
          <th>Estatus</th>
          <th>Nombre</th>
          <th>Fecha Inicio</th>
          <th>Horario</th>
          <th>Duración</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c, key) in cursos" :key="key">
          <td>{{ c.categoria.description }}</td>
          <td>{{ c.status ? 'Activo':'Inactivo' }}</td>
          <td>{{ c.name }}</td>
          <td>{{ c.fecha }}</td>
          <td>{{ c.horario }}</td>
          <td>{{ c.duracion }}</td>
          <td>
            <div class="d-flex btn-group">
              <button type="button" class="btn btn-primary" @click="edit(c)">Editar</button>
              <button type="button" class="btn btn-danger"  @click="eliminar(c)">Eliminar</button>
              <!-- <button type="button" class="btn btn-info"    @click="change(c)">{{ !c.status ? 'Activar':'Desactivar' }}</button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: '',
  mixins:[],
  components: {
    
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('AdminCourses',['cursos']),
  },
  mounted(){
    window.scrollTo( 0, 0 );
    this.getCursos()
  },
  methods:{
    ...mapActions('AdminCourses',['getCursos','destroyCurso']),
    ...mapMutations('AdminCourses',['changeCurseActive']),
    edit(curso){
      this.changeCurseActive(curso.id)
      this.$router.push({name:'EditCurso',params:{ id: curso.id}})
    },
    eliminar(curso){
      if (confirm('Eliminar?')) {
        this.destroyCurso(curso.id)
      }
    },
    change(curso){
      if (confirm('Cambiar estatus?')) {
        alert(curso)
      }
    },
  }
}
</script>

<style>

</style>
