import swal from 'sweetalert';
import { db } from '../main.js'

export default {
	namespaced: true,
	state:{
		rasgos:[],
		rasgosModificados: [],
		paso: null,
		idPaciente: null,
		datosPaciente: {},
		datosP: {},
		existe: false,
		ultimoPaso: false,
		categorias:['ETIOLOGÍA','SISTEMA DE CREENCIAS','DINÁMICA AFECTIVA','FÍSICO-ENERGÉTICO'],
		subcategoriasRigidos:['HISTERICO','PASIVO FEMENINO','MASCULINO AGRESIVO','FALICO-NARCISISTA'],
		backgrounds: [{id:0, color:'#7AE73C'},{id:1, color:'#3CD0E7'},{id:2, color:'#A93CE7'},{id:3, color:'#E7533C'},{id:4, color:'#E51FC1'}],
		rasgosTipos:['ESQUIZOIDE','ORAL','PSICOPÁTICO','RÍGIDO','MASOQUISTA'],
		sex: ''
	},
	getters:{
		rasgosAleatorios: state => {
			// console.log(state.rasgosModificados)
			return state.rasgosModificados.slice(0).sort(function(){return Math.random() - 0.5})
		}
	},
	mutations:{
		changedatosP(state, val){
			state.datosP = val
		},
		changeSex(state, val){
			state.sex = val
			state.rasgosModificados = state.rasgos

			let rasgoss = state.rasgosModificados.map((e)=>{
				if (e.rasgo == 'RÍGIDO') {
					if (e.subcategoria != '') {
						if (state.sex == 'M') {
							if (e.subcategoria == 'PASIVO FEMENINO' || e.subcategoria == "FALICO-NARCISISTA") {
								return e
							}
						}else if (state.sex == 'F'){
							if (e.subcategoria == "HISTERICO" || e.subcategoria =='MASCULINO AGRESIVO') {
								return e
							}
						}
					}else{ 
						return e
					}
				}else{
					return e
				}
			})
			console.log(rasgoss)
			let data = []
			rasgoss.findIndex((element, index) => {
				if (element === undefined) {
					rasgoss.splice(index, 1);
				}else{
					data.push(element)
				}
			})
			rasgoss = data

			state.rasgosModificados = rasgoss
		},
		responde(context, val){

			if (val != false) {
				context.rasgosModificados.filter((e)=>{

					if (e.id == val) {
						e.selected = true
					}
				})
			}
		},
		cambioPaso(context){
			if (context.paso == null) {
				context.paso = 0
			}else{
				context.paso++
			}
		},
		LlenarIDPaciente(context, val){
			context.idPaciente = val
		},
		cambiarUltimoPaso(context){
			context.ultimoPaso = true
		},
		modificaRasgos(context, val){
			context.rasgos = val
		},
		ExisteUsuario(context,val){
			context.existe = val
		},
		llenarDatosPaciente(context, val){
			context.datosPaciente = val
		}
	},
	actions:{
		cambioPasoAction(context){
			context.commit('cambioPaso')
			window.scrollTo( 0, 0 );
			// context.dispatch('updated')
			if (context.state.paso >= context.getters.rasgosAleatorios.length) {
				context.commit('cambiarUltimoPaso')
				context.dispatch('updated')
			}
		},
		updated(context){
			if (context.state.datosPaciente.name == '' || context.state.datosPaciente.name == null) {
				swal("Espera :(", "Lo sentimos no hemos logrado registrar tus datos. Intentalo nuevamente", "error").then(()=>{
				location.reload();
				})
				return false
			}
			if (context.state.idPaciente) {
				db.collection("pacientes").doc(context.state.idPaciente).set({
					name: context.state.datosPaciente.name,
					email: context.state.datosPaciente.email,
					TestGrupo: context.state.datosPaciente.TestGrupo,
					sexo: context.state.datosPaciente.sexo,
					created_at: context.state.datosPaciente.created_at,
					rasgos: context.state.rasgosModificados
				},{ merge: true })
				.then(function() {
					
					console.log("Document successfully written!");
				})
				.catch(function(error) {
					console.error("Error writing document: ", error);
				});
			}
		}
	}
}
