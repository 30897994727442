<template>
  <div class="container-fluid">
    <h4 class="my-4 text-white">Pacientes que respondieron el Test</h4>
    <div class="card mb-4 ">
      <div class="card-body">
          <div class="table-responsive">
              <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombres</th>
                        <th>Email</th>
                        <th>Fecha</th>
                        <th>Acciones</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, key) in data" :key="key">
                      <td>{{ i.id }}</td>
                      <td>{{i.datos.name}}</td>
                      <td>{{i.datos.email}}</td>
                      <td>{{i.datos.created_at}}</td>
                      <td>
                        <router-link :to="{ name: 'Resultados', params: { id: i.id }}" class="btn btn-success btn-sm"><i class="fas fa-eye"></i></router-link>
                        <button type="button" @click="delet(i.id)" class="btn btn-danger ml-2 btn-sm"><i class="fas fa-trash-alt"></i></button>
                      </td>
                    </tr> 
                  </tbody>
              </table>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PacientesIndex',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Pacientes',['data']),
  },
  mounted(){
  
  },
  methods:{
 
    
  }
}
</script>

<style>

</style>
