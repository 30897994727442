<template>
  <div class="home">
  <!-- <Menu></Menu> -->
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top">
      <div class="container d-flex align-items-center">

        <!-- <h1 class="logo mr-auto"><a href="index.html">Mentor</a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo -->
        <a href="/" class="logo mr-auto"><img :src="require('./assets/logo.png')" alt="" class="img-fluid"></a>

        <Menus></Menus> <!-- .nav-menu -->

        <!-- <a href="courses.html" class="get-started-btn">Get Started</a> -->

      </div>
    </header><!-- End Header -->

    <router-view/>

    <!-- ======= Footer ======= -->
    <footer id="footer">

      <div class="footer-top">
        <div class="container">
          <div class="row">

            <div class="col-lg-3 col-md-6 footer-contact">
              <h3>Asofipsicos</h3>
              <p>
               <!--  A108 Adam Street <br>
                New York, NY 535022<br>
                United States <br><br> -->
                <strong>Teléfono:</strong> +54 1132954245<br>
                <strong>Email:</strong> hola@asofipsicos.com<br>
              </p>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <h4>Enlaces útiles</h4>
              <ul>
                <li v-for="(item, key) in routers" :key="key">
                  <i class="bx bx-chevron-right"></i>
                  <router-link  :to="item.link"> {{ item.name }}</router-link>
                </li>
              </ul>
            </div>
<!-- TODO ALGUNOS ARRAY DE CURSOS Y CONSULTAS  -->
            <!-- <div class="col-lg-3 col-md-6 footer-links">
              <h4>Nuestros servicios</h4> 
              <ul>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
              </ul>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
              <form action="" method="post">
                <input type="email" name="email"><input type="submit" value="Subscribe">
              </form>
            </div> -->

          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4">

        <div class="mr-md-auto text-center text-md-left">
          <div class="copyright">
            &copy; Copyright <strong><span>Asofipsicos</span></strong>. All Rights Reserved
          </div>
          <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/mentor-free-education-bootstrap-theme/ -->
            <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
          </div>
        </div>
        <div class="social-links text-center text-md-right pt-3 pt-md-0">
          <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
          <a href="https://www.facebook.com/asdrubal.noguera.12" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.instagram.com/fipsico/?hl=es-la" class="instagram"><i class="bx bxl-instagram"></i></a>
          <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
          <!-- <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
        </div>
      </div>
    </footer><!-- End Footer -->
  </div>
</template>
<script>
import Menus from './components/menu.vue'
import { mapState, mapActions } from 'vuex'
// import axios from 'axios'
export default {
  name: 'App',
  components: {
    Menus
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('Home',['routers']),
  },
  mounted(){
    this.getCursos()
  },
  methods:{
    ...mapActions('Courses',['getCursos']),
  }
}
</script>

<style>

</style>
