import { mapMutations, mapState } from 'vuex'
// import moment from 'moment'
import { db } from '../../main.js'
import swal from 'sweetalert';
export const PeguntasModel = {
	data(){
		return{
			nameModel: 'Preguntas'
		}
	},
	computed:{
		...mapState('Test',['rasgos']),
		conteo(){
			return this.rasgos.length + 1
		}
	},
	methods:{
		...mapMutations('Test',['modificaRasgos']),
		addPregunta(element){
			element.id = this.conteo
			element.selected = false
			db.collection(this.nameModel).add(element)
			.then((docRef)=> {
				console.log(docRef)
				swal("Felicidades!!", "Has creado una nueva pregunta", "success").then(()=>{
					location.reload();
				});
			})
			.catch(function(error) {
				console.error("Error adding document: ", error);
			});
		},
		updatedPregunta(element){
			
			db.collection(this.nameModel).doc(element.idg).set(element)
			.then(()=> {
				swal("Felicidades!!", "La pregunta se edito correctamente", "success");
			})
			.catch(function(error) {
				console.error("Error writing document: ", error);
			});
		},
		getPreguntas(){
			var dato = []
			db.collection(this.nameModel).orderBy("id", "asc").get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					var data = doc.data()
					data.idg = doc.id
					// console.log(data)
					dato.push(data)
				});
			});
			this.modificaRasgos(dato)
		},
		
	}
}