<template>
  <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="course-item">
              <img :src="URL_IMG+element.img" class="img-fluid" :alt="element.name">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4 :style="{backgroundColor: `${element.categoria.ccolor}`}">{{ element.categoria.description }}</h4>
                  <!-- <p class="price">$169</p> -->
                </div>

                <h3><router-link  :to="`courses/${element.slug}`">{{ element.name }}</router-link></h3>
                <!-- <p>{{ element.resumen }}</p> -->
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <!-- <img :src="element.img_profesor" class="img-fluid" alt=""> -->
                    <span>{{ element.profesor.name }}</span>
                  </div>
                  <!-- <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;50
                    &nbsp;&nbsp;
                    <i class="bx bx-heart"></i>&nbsp;65
                  </div> -->
                </div>
              </div>
            </div>
          </div> 
</template>

<script>

export default {
  name: 'boxCurso',
  props:{
    element:{
      type: Object, 
     } 
  },
  components: {

  },
  data(){
    return{
      URL_IMG: process.env.VUE_APP_IMG
    }
    
  },
  computed:{
  
  },
  mounted(){
  },
  methods:{
    
  }
}
</script>

<style lang="scss">

</style>
