<template>
  <div class="container-fluid">
    <h4 class="my-4 text-white">Crea un nuevo Grupo</h4>
    <div class="card mb-4 ">
      <div class="card-body">
        <form class="" @submit.prevent="addGrup">
          <h5>Crear un nuevo grupo</h5>
          <div class="form-group">
            <label for="name">Nombre del Grupo</label>
            <input type="text" class="form-control" id="name" v-model="name" required="" placeholder="Ingrese el nombre del grupo">
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="status" id="activo" :value="true" v-model="status" checked>
            <label class="form-check-label" for="activo">
              Activo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="status" id="inactivo" :value="false" v-model="status" >
            <label class="form-check-label" for="inactivo">
              Inactivo
            </label>
          </div>
          <div class="col-12 mt-4 px-0">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import { auth } from '../main.js'
import { GruposModel } from '../../../mixins/models/grupos.js' 
export default {
  name: 'GruposCreate',
  mixins:[GruposModel],
  components: {

  },
  data(){
    return{
      name: null,
      status: true
    }
  },
  computed:{
  
  },
  mounted(){
  },
  methods:{
    addGrup(){
      this.addGrupo(this.name, this.status)
      this.name = null
    }
  }
}
</script>

<style>

</style>
