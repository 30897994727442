// import { db } from '../main.js'
export default {
	namespaced: true,
	state:{
		gruposList:[],
		grupoActivo: {},
		PacientesGrupos:[],
	},
	getters:{
	},
	mutations:{
		LlenarDataGrupos(context, val){
			context.gruposList = val
		},
		LimpiarDataGrupos(context){
			context.gruposList = []
		},
		LlenarPacientesGrupos(context, val){
			context.PacientesGrupos = val
		},
		LlenarGrupoActivo(context,val){
			context.grupoActivo = val
		},
	},
	actions:{
		
	}
}
