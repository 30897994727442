import axios from 'axios'

export default {
  namespaced: true,
  state:{
    cursos:[],
    categorias: [],
    profesores:[],
    cursoActive: null,
    curso: {}
  },
  getters:{
    filterCourse(state){
    if (state.cursos) {
      return state.cursos.find((element)=> element.id == state.cursoActive)
    }
    return null
   }
  },
  mutations:{
   changeCurseActive(state, val){
    state.cursoActive = val
   },
   changeCurso(state, val){
    state.cursos.filter((element)=> {
      if (element.id == val.id) {
        element = val
      }
    }) 
   },
   deleteCurso(state, val){
    let index = state.cursos.findIndex((element) => element.id == val)
    console.log(index)
    state.cursos.splice(index, 1)
   },
   addCurso(state, val){
    state.cursos.push(val)
   }

  },
  actions:{
    getCursos(context){
      axios.get(process.env.VUE_APP_API+'/admin/cursos').then(function (response) {
        context.state.cursos = response.data.cursos
        context.state.categorias = response.data.categorias
        context.state.profesores = response.data.profesores
      }).catch(function (error) {
        console.log(error);
      });
    },
    async SenData(context, data){

      let formData = new FormData()
      formData.append('name',               data.name)
      formData.append('status',             data.status)
      formData.append('categorias_curso_id',data.categorias_curso_id)
      formData.append('profesores_id',      data.profesores_id)
      formData.append('duracion',           data.duracion)
      formData.append('fecha',              data.fecha)
      formData.append('horario',            data.horario)
      formData.append('ccolor',             data.ccolor)
      formData.append('resumen',            data.resumen)
      formData.append('descripcion',        data.descripcion)
      formData.append('img',                data.img)
      formData.append('contenidos',         JSON.stringify(data.contenidos))
      let url = process.env.VUE_APP_API+'/admin/cursos'
      if (data.edit) {
        url = process.env.VUE_APP_API+'/admin/cursos/'+data.id
      }
      await axios.post(url,formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response)=> {
        
        if (data.edit) {
          context.commit('changeCurso',response.data)
        }else{
          context.commit('addCurso',response.data)
        }
        
        return response.data
      }).catch(function (error) {
        console.log(error);
      });
    },
    destroyCurso(context, val){
      axios.post(process.env.VUE_APP_API+'/admin/cursos/destroy/'+val).then(function (response) {
        console.log(response.data.mensaje)
        context.commit('deleteCurso',val)
      }).catch(function (error) {
        console.log(error);
      });
    }
    
    
  }
}
