<template>
  <nav class="nav-menu d-none d-lg-block">
    <ul>
      <!-- <li class="active"><a href="/">home</a></li> -->
      
      <li v-for="(item, itemkey) in routers" :key="itemkey" :class="item.drop ? 'drop-down':''">
        <router-link  :to="item.link"> {{ item.name }}</router-link>
        <ul v-if="item.drop">
          <li v-for="(drop, dropkey) in item.drop" :key="dropkey" >
            <router-link  :to="drop.link"> {{ drop.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Menus',
  components: {
  },
  data(){
    return{
      
    }
  },
  computed:{
    ...mapState('Home',['routers']),
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>

<style lang="scss">

</style>
