// import { db } from '../main.js'
export default {
	namespaced: true,
	state:{
		data:[],
		pacienteTest:[]
	},
	getters:{

	},
	mutations:{
		LlenarData(context, val){
			context.data = val
		},
		LlenaPacienteTest(context, val){
			context.pacienteTest = val
		}
	},
	actions:{
		
	}
}
