import Vue from 'vue'
import VueRouter from 'vue-router'
// HOME
import Home from '../views/home/home.vue'
import Association from '../views/home/association.vue'
import Courses from '../views/home/formaciones.vue'
import CoursesDetail from '../views/home/detail.vue'
import Services from '../views/home/services.vue'
import Contact from '../views/home/contact.vue'
import Test from '../views/home/test.vue'

////////
import Login from '../views/admin/Login.vue'
// // ADMIN
import Admin from '../views/admin/index.vue'
import CardMenu from '../views/admin/menu/CardMenu.vue'
//
import Pacientes from '../views/admin/pacientes/index.vue'
import PacientesList from '../views/admin/pacientes/list.vue'
import PacientesResults from '../views/admin/pacientes/results.vue'
//
import Grupos from '../views/admin/grupos/index.vue'
import GruposList from '../views/admin/grupos/GruposList.vue'
import GruposCreate from '../views/admin/grupos/GruposCreate.vue'
import GruposResults from '../views/admin/grupos/GruposResults.vue'
//
import Preguntas from '../views/admin/preguntas/index.vue'
// /////
import CursosList from '../views/admin/cursos/CursosList.vue'
import EditCurso from '../views/admin/cursos/EditCurso.vue'
import CreateCurso from '../views/admin/cursos/CreateCurso.vue'
/////////
import AsistencialList from '../views/admin/asistencial/AsistencialList.vue'
import CreateService from '../views/admin/asistencial/CreateService.vue'
import EditService from '../views/admin/asistencial/EditService.vue'
////////////////////////////////
import firebase from "firebase/app";
import 'firebase/app';
import 'firebase/auth';

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes: [
		// dynamic segments start with a colon
		{ path: '/', component: Home },
		{ path: '/test', component: Test },
		{ path: '/association', component: Association },
		{ path: '/courses', component: Courses },
		{ path: '/courses/:slug', component: CoursesDetail },
		{ path: '/consultations', component: Services },
		{ path: '/contact', component: Contact },
		
		{ path: '/login', name:'Login', component: Login },
		{ path: '/admin', name: 'Admin', component: Admin,
			meta:{
				requiresAuth: true
			},
			children: [
				{ path: '', name:'CardMenu', component: CardMenu },
				{ path: 'pacientes', 
					name:'Pacientes', 
					component: Pacientes,
					children: [
						{ path: '', name:'PacientesList', component: PacientesList },
						{ path: 'resultados/:id', name:'Resultados', component: PacientesResults },
					]
				},
				{	path: 'grupos', 
					component: Grupos,
					name:'Grupos',
					children: [
						{ path: '', name:'GruposList', component: GruposList },
						{ path: 'create', name:'GruposCreate', component: GruposCreate },
						{ path: 'results/:id/:name', name:'GruposResults', component: GruposResults },

					]
				},
				{	path: 'preguntas',
					name: 'Preguntas',
					component: Preguntas,
				},
				{
					path: 'CursosList',
					name: 'CursosList',
					component: CursosList
				},
				{
					path: 'edit_curso/:id',
					name: 'EditCurso',
					component: EditCurso,
				},
				{
					path: 'CreateCurso',
					name: 'CreateCurso',
					component: CreateCurso,
				},
				{
					path: 'AsistencialList',
					name: 'AsistencialList',
					component: AsistencialList,
				},
				{
					path: 'CreateService',
					name: 'CreateService',
					component: CreateService,
				},
				{
					path: 'edit_service/:id',
					name: 'EditService',
					component: EditService,
				},
				
			]
		},
	]
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if(requiresAuth) {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) next('/login')
      else next();
    })
  } else next()
});
export default router;