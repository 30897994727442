<template>
  <div class="container-fluid">
    <h4 class="my-4 text-white">{{nameGrupo}}</h4>
    <!-- <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Paciente</th>
          <th scope="col">Email</th>
          <th scope="col">Fecha</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, key) in PacientesGrupos" :key="key">
          <th>{{i.name}}</th>
          <td>{{i.email}}</td>
          <td>{{i.created_at}}</td>
          <td>
            <router-link :to="{ name: 'Resultados', params: { id: i.id }}" class="btn btn-success btn-sm"><i class="fas fa-eye"></i></router-link>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div class="row" v-if="isLoading">
      <div class="col-12 col-md-8 mb-4">
        <div class="shadow h-100 mb-5 p-4 bg-white rounded">
          <GrafGrupos :ragosDePacientes="ragosDePacientes"></GrafGrupos>
        </div>
      </div> 
      <div class="col-md-4 col-12 mb-4">
        <div class="shadow h-100  p-4 bg-white rounded">
          <h5>Caractéristicas rígidas</h5>
          <hr>
          <GrafRigidoCircle :ragosDePacientes="ragosDePacientes"></GrafRigidoCircle>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4" v-for="(i, key) in categorias" :key="key" >
        <div class="shadow h-100  p-4 bg-white rounded">
          <h5>{{ i }}</h5>
          <hr>
          <BarHorizontal :categoria="i" :ragosDePacientes="ragosDePacientes"></BarHorizontal>
          <!-- <BarHorizontalRigido :categoria="i"></BarHorizontalRigido> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { database } from '../../../mixins/database.js' 
import GrafGrupos from '../../../components/Grupos/Graf.vue'
import GrafRigidoCircle from '../../../components/Grupos/GrafRigidoCircle.vue'
import BarHorizontal from '../../../components/Grupos/BarHorizontal.vue'
// import BarHorizontalRigido from '../../components/Grupos/BarHorizontalRigido.vue'
export default {
  name: 'results',
  mixins:[database],
  components: {
    GrafGrupos,
    GrafRigidoCircle,
    BarHorizontal,
    // BarHorizontalRigido
  },
  data(){
    return{
      idGrupo: null,
      nameGrupo: null,
      isLoading: false,
    }
  },
  watch: {
    ragosDePacientes: function(){
      this.isLoading = true
    }
  },
  computed:{
    ...mapState('Grupos',['gruposList','grupoActivo','PacientesGrupos']),
    ...mapState('Test',['categorias']),
    ragosDePacientes(){
      var data = []
      this.PacientesGrupos.filter((e)=>{
        data.push(e.rasgos)
      })
      return data
    }
  },
  created(){
    this.idGrupo = this.$route.params.id
    this.nameGrupo = this.$route.params.name
    if (!this.idGrupo || !this.nameGrupo) {
      this.$router.replace('/404')
    }
  },
  mounted(){
    this.getPacientesGrupos(this.idGrupo)
    // console.log(this.ragosDePacientes)
  },
  methods:{
    ...mapMutations('Grupos',['LlenarGrupoActivo'])
  }
}
</script>

<style>

</style>
