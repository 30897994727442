<template>
  <div>
    <h1>Cursos</h1>
    <form action="#" @submit.prevent="Sen" enctype="multipart/form-data" method="post">
      <div class="row" v-if="curso">
        <div class="form-group col-4">
          <label for="">Nombre</label>
          <input type="text" v-model="curso.name" class="form-control">
        </div>
        <div class="form-group col-4">
          <label for="">Estatus</label>
          <select name="" class="form-control" v-model="curso.status" id="">
            <option value="">Seleccione</option>
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="">Categoria</label>
          <select name="" class="form-control" v-model="curso.categorias_curso_id" id="">
            <option value="">Seleccione</option>
            <option :value="c.id" v-for="(c, key) in categorias" :key="key">{{ c.description }}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="">Profesores</label>
          <select name="" class="form-control" v-model="curso.profesores_id" id="">
            <option value="">Seleccione</option>
            <option :value="c.id" v-for="(c, key) in profesores" :key="key">{{ c.name }}</option>
          </select>
        </div>
        <div class="form-group col-4">
          <label for="">Duracion</label>
          <input type="text" v-model="curso.duracion" class="form-control">
        </div>
        <div class="form-group col-4">
          <label for="">fecha</label>
          <input type="date" v-model="curso.fecha" class="form-control">
        </div>
        <div class="form-group col-4">
          <label for="">horario</label>
          <input type="text" v-model="curso.horario" class="form-control">
        </div>
         <div class="form-group col-4">
          <label for="">ccolor</label>
          <input type="color" v-model="curso.ccolor" class="form-control">
        </div>
        <div class="form-group col-4">
          <label for="">img</label>
          <input type="file" @change="changeFile" ref="file" class="form-control">
        </div>
         <div class="form-group col-4">
          <label for="">resumen</label>
          <textarea name="" v-model="curso.resumen" class="form-control" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="form-group col-4">
          <label for="">descripcion</label>
          <textarea name="" v-model="curso.descripcion" class="form-control" id="" cols="30" rows="10"></textarea>
        </div>
      </div>
      <div class="row">
       <div class="col-12">
          <h2>Contenidos</h2>
       </div>
        <div v-for="(c, key) in contenidos" :key="key" class="d-flex col-12">
          <div class="form-group col-4">
            <label for="">Nombre</label>
            <input type="text" class="form-control" v-model="c.name">
          </div>
          <div class="form-group col-6">
            <label for="">Descripcion</label>
            <input type="text" class="form-control" v-model="c.descripcion">
          </div>
          <span>
            <button v-if="(key+1) == contenidos.length " type="button" class="btn btn-primary mt-4" @click="addContenido(key)">Agregar </button>
            <button v-else type="button" class="btn btn-danger mt-4" @click="removeContenido(key)">Borrar</button>
          </span>
        </div>
      </div>
      <div class="form-group col-12">
          <input type="submit" class="btn btn-primary " value="Guardar">
        </div>
    </form>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import swal from 'sweetalert'
export default {
  name: '',
  mixins:[],
  components: {
    
  },
  data(){
    return{
      curso: null,
      contenidos:[{name: '', descripcion: ''}]
    }
  },
  watch:{
    cursos: 'llenarCampos'
  },
  computed:{
    ...mapState('AdminCourses',['cursos', 'cursoActive','categorias','profesores']),
    ...mapGetters('AdminCourses',['filterCourse'])
  },
  mounted(){
    window.scrollTo( 0, 0 );
    this.curso = this.filterCourse
    if (this.curso) {
    if (this.curso.contenidos.length > 0) {
      this.contenidos = this.curso.contenidos
     }
   }
  },
  methods:{
    ...mapActions('AdminCourses',['getCursos','SenData']),
    ...mapMutations('AdminCourses',['changeCurseActive']),
    llenarCampos(){
     this.changeCurseActive(this.$route.params.id)
     this.curso = this.filterCourse
     if (this.curso.contenidos.length > 0) {
      this.contenidos = this.curso.contenidos
     }
    },
    Sen(){
      this.curso.edit = true
      this.curso.contenidos = this.contenidos
      this.SenData(this.curso)
      swal('Registro almacenado')
       // this.$router.push({name:'CursosList'})
    },
    changeFile(){
       this.curso.img = this.$refs.file.files[0];
    },
    addContenido(key){
      if(this.contenidos[key].name != '' && this.contenidos[key].descripcion != ''){
        this.contenidos.push({name: '', descripcion: ''})
      }
    },
    removeContenido(key){
      this.contenidos.splice(key,1)
    }
  }
}
</script>

<style>

</style>
