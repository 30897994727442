<template>
  <router-view></router-view>
</template>

<script>
// import { auth } from '../main.js'
export default {
  name: 'Grupos',
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
  
  },
  mounted(){

  },
  methods:{
  }
}
</script>

<style>

</style>
