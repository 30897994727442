<template>
  <div id="layoutAuthentication" class="admin">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
              <div class="col-lg-5">
                  <div class="card shadow-lg border-0 rounded-lg mt-5 card-transparent">
                      <div class="card-header"><h3 class="text-center font-weight-light my-4">Login</h3></div>
                      <div class="card-body">
                          <form @submit.prevent="ingresar">
                              <div class="form-group">
                                  <label class="small mb-1" for="inputEmailAddress">Email</label>
                                  <input class="form-control form-control-dark py-4" id="inputEmailAddress" type="email" v-model="email" placeholder="Enter email address" />
                              </div>
                              <div class="form-group">
                                  <label class="small mb-1" for="inputPassword">Password</label>
                                  <input class="form-control form-control-dark py-4" id="inputPassword" type="password" v-model="password" placeholder="Enter password" />
                              </div>
                              <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                  <button type="submit" class="btn btn-primary col-sm-3">Ingresar</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { auth } from '../../main.js'
export default {
  name: 'Login',
  components: {

  },
  data(){
    return{
      email: '',
      password: ''
    }
  },
  computed:{
  
  },
  mounted(){

  },
  methods:{
    ingresar(){
      auth.signInWithEmailAndPassword(this.email, this.password).then( () =>  {
          this.$router.replace('/admin')
        }).catch(function(error) {
          console.log(error.message)
        });
    }
  }
}
</script>

<style>

</style>
