import axios from 'axios'
export default {
  namespaced: true,
  state:{
    categorias:[
      'Todo',
      'Talleres',
      'Cursos',
      'Diplomados',
    ],
    catActive: 'Todo',
    cursos:[],
    cursoActive: null, 
  },
  getters:{
    cursosFilter: state => {
      var cursos = state.cursos
      if (state.catActive != 'Todo') {
        var a = []
        state.cursos.filter(element=>{
          if (element.categoria.description == state.catActive) {
            a.push(element)
          }
        })
        return a
      }
      return cursos
    },
    cursosLimit: state =>{
      const MAX = 6
      var a = []
      state.cursos.filter((element, index)=>{
        if (index < MAX) {
          a.push(element)
        }
      })
      return a
    },
    cursoDetail: state =>{
      return state.cursos.find(element => element.slug == state.cursoActive)
    }
  },
  mutations:{
    filterActive(state, val){
      state.catActive = val
    },
    cursoActive(state, val){
      state.cursoActive = val
    }
  },
  actions:{
    getCursos(context){
      axios.get(process.env.VUE_APP_API+'/cursos').then(function (response) {
        context.state.cursos = response.data
      }).catch(function (error) {
        console.log(error);
      });
    },
    getContenido(context, val){
      console.log('este',val)
      axios.get(process.env.VUE_APP_API+'get/contenido/'+val).then((response)=>{
        console.log(response)
        context.state.cursos.filter(element=>{
          if (response.status) {
            if (element.id == val) {
              element.contenido = response.data
            }
          }
        });
        // console.log(response.data.)
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
}
